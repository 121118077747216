<template>
  <div class="caseClip_Course" :style="{ minHeight: `${height}`,}">
    <a-row class="data-count">
      <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日病历夹访问人数</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>本月访问总人数&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日回收站访问人数</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportUserNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportUserNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>本月访问总人数&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportUserNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="6" >
        <div style="padding-right: 20px">
          <a-card :bordered="false" style="height: 130px">
            <div class="data-count-day">
              <div>
                <span>今日新建患者数量</span>
              </div>
              <div class="data-count-day-sum">
                <h1 style="font-size: xx-large">{{ ExportWordNum.today }}</h1>
                <div>
                  <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportWordNum.yesterday }}</span>
                </div>
              </div>
              <a-divider />
              <div class="data-count-day-sum">
                <span>患者总数量&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportWordNum.total }}</span></span>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="6">
        <a-card :bordered="false" style="height: 130px;">
          <div class="data-count-day">
            <div>
              <span>今日新建病程数量</span>
            </div>
            <div class="data-count-day-sum">
              <h1 style="font-size: xx-large">{{ ExportExcelNum.today }}</h1>
              <div>
                <span>昨日</span>&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportExcelNum.yesterday }}</span>
              </div>
            </div>
            <a-divider />
            <div class="data-count-day-sum">
              <span>病程总数量&nbsp;&nbsp;&nbsp;<span style="color: black">{{ ExportExcelNum.total }}</span></span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div class="date-day">
      <div class="box-header">
        <h3>功能使用（日）</h3>
        <a-range-picker v-model="day" @change="day_picker" :disabled-date="disabledDate" />
      </div>
      <div class="box-line" id="dayLine">

      </div>

    </div>
    <div class="date-day">
      <div class="box-header">
        <h3>功能使用（月）</h3>
        <div>
          <a-month-picker v-model="month[0]"
                          valueFormat="YYYY-MM"
                          :disabled-date="disabledDate"
                          placeholder="开始月份"
                          @change="month_picker()"

          />
          ~
          <a-month-picker v-model="month[1]"
                          valueFormat="YYYY-MM"
                          :disabled-date="disabledDate"
                          placeholder="结束月份"
                          @change="month_picker()"

          />
        </div>
      </div>
      <div class="box-line" id="monthLine">

      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from 'echarts';
import {
  getCaseExportList,
  caseExportNum,
  caseExportExcelNum,
  caseExportWordNum,
  caseExportUserNum,
  caseFolderNum, caseBinNum, caseFunNum, casePatientNum, caseDetailNum
} from "@/service/caseClip_api";
export default {
  name: "caseClip_Course",
  data(){
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    };
    const disabledMonth = current => {
      return current && current > moment().endOf('month')
    };
    return{
      height:window.innerHeight-64-30 + "px",
      day:[moment().subtract(3,'month').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
      month:[moment().subtract(6,'month').format('YYYY-MM'),moment().format('YYYY-MM')],
      disabledDate,
      disabledMonth,
      dayData: {
        userNum:[],
        wordNum:[],
        excelNum:[],
        casesNum:[],
      },
      monthData: {
        userNum:[],
        wordNum:[],
        excelNum:[],
        casesNum:[],
      },
      ExportNum:{},
      ExportUserNum:{},
      ExportWordNum:{},
      ExportExcelNum:{},
    }
  },
  mounted() {
    this.get_caseExportNum()
    this.get_caseExportUserNum()
    this.get_caseExportWordNum()
    this.get_caseExportExcelNum()
    this.getCaseData('days',this.day[0],this.day[1])
    this.getCaseData('months',this.month[0],this.month[1])
  },
  methods:{
    async get_caseExportNum(){
      const info = await caseFolderNum()
      if (info.code===0){
        this.ExportNum=info.data
      }
    },
    async get_caseExportUserNum(){
      const info = await caseBinNum()
      if (info.code===0){
        this.ExportUserNum=info.data
      }
    },
    async get_caseExportWordNum(){
      const info = await casePatientNum()
      if (info.code===0){
        this.ExportWordNum=info.data
      }
    },
    async get_caseExportExcelNum(){
      const info = await caseDetailNum()
      if (info.code===0){
        this.ExportExcelNum=info.data
      }
    },


    async getCaseData(type,startTime,endTime){
      const data={
        type:type,
        startTime:startTime,
        endTime:endTime,
      }
      const info=await caseFunNum(data)
      if (info.code===0){
        switch (type){
          case 'days':
            this.dayData={
              userNum:[],
              wordNum:[],
              excelNum:[],
              casesNum:[],
            }
            info.data.forEach((value,index)=>{
              this.dayData.casesNum.push({
                time:value.days,
                sum:value.cases,
              })
              this.dayData.userNum.push({
                time:value.days,
                sum:value.bin,
              })
              this.dayData.wordNum.push({
                time:value.days,
                sum:value.casePatient,
              })
              this.dayData.excelNum.push({
                time:value.days,
                sum:value.caseDetail,
              })
            })
            this.line_day()
            break;
          case 'months':
            this.monthData={
              userNum:[],
              wordNum:[],
              excelNum:[],
              casesNum:[],
            }
            info.data.forEach((value,index)=>{
              this.monthData.casesNum.push({
                time:value.days,
                sum:value.cases,
              })
              this.monthData.userNum.push({
                time:value.days,
                sum:value.bin,
              })
              this.monthData.wordNum.push({
                time:value.days,
                sum:value.casePatient,
              })
              this.monthData.excelNum.push({
                time:value.days,
                sum:value.caseDetail,
              })
            })
            this.line_month()
            break;
        }

      }
    },
    day_picker(date, dateString){
      this.day=dateString
      this.getCaseData('days',this.day[0],this.day[1])
    },
    month_picker(date, dateString,type){
      console.log(this.month)
      if (this.month[1]<this.month[0]){
        this.$message.error('结束日期不能大于开始日期')
      }else {
        this.getCaseData('months',this.month[0],this.month[1])
      }
    },

    line_day(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('dayLine'));
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ['新建患者数量','新建病程数量']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {},
        dataset: [
          {
            source: this.dayData.casesNum
          },
          {
            source: this.dayData.userNum
          },
          {
            source: this.dayData.wordNum
          },
          {
            source: this.dayData.excelNum
          }
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          // {
          //   datasetIndex:0,
          //   name:'病历夹访人数',
          //   type:'line',
          //   smooth: true,
          // },
          // {
          //   datasetIndex:1,
          //   name:'回收站访问人数',
          //   type:'line',
          //   smooth: true,
          // },
          {
            datasetIndex:2,
            name:'新建患者数量',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:3,
            name:'新建病程数量',
            type:'line',
            smooth: true,
          },
        ]
      });
    },
    line_month(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('monthLine'));
      // 绘制图表
      myChart.setOption({
        legend: {
          data: [ '新建患者数量','新建病程数量']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {},
        dataset: [
          {
            source: this.monthData.casesNum
          },
          {
            source: this.monthData.userNum
          },
          {
            source: this.monthData.wordNum
          },
          {
            source: this.monthData.excelNum
          }
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          // {
          //   datasetIndex:0,
          //   name:'病历夹访人数',
          //   type:'line',
          //   smooth: true,
          // },
          // {
          //   datasetIndex:1,
          //   name:'回收站访问人数',
          //   type:'line',
          //   smooth: true,
          // },
          {
            datasetIndex:2,
            name:'新建患者数量',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:3,
            name:'新建病程数量',
            type:'line',
            smooth: true,
          },
        ]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-divider{
  margin:0 0 10px 0;
}
h1,h2,h3{
  margin:0;
}
.caseClip_Course{
  margin: -15px;
  background-color: #f0f2f5;
  height: auto;
  .data-count{

    h1{
      margin:0;
    }
    span{
      color: rgba(0, 0, 0, 0.427450980392157);
    }
    display: flex;
    justify-content: space-between;
    .data-count-day{
      .data-count-day-sum{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .date-day{
    margin-top: 20px;
    background: white;
    min-height: 500px;
    .box-header{
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
    }
    .box-line{
      padding: 10px 20px;
      width: 98%;
      min-height: 400px;
      height: auto;
    }
  }
}
</style>